import "./Landing.css";
import React from "react";
import Hero from "./Hero";
import Projects from "./Projects";
import snow from "../assets/snow.mp4";
import snowWebm from "../assets/snow.webm";

function Landing({
  setOpen,
  onSelect,
  setToggleImpressum,
  toggleImpressum,
  setToggleDataProtection,
  dataProtectionToggle,
}) {
  return (
    <div className="App">
      <div
        dangerouslySetInnerHTML={{
          __html: `<video autoplay loop muted playsinline>
      <source src=${snow} type="video/mp4" />
      <source src=${snowWebm} type="video/webm"/>
      Your browser does not support the video tag.
</video>`,
        }}
      />
      <div className="landing-container">
        <Hero setOpen={setOpen} />
        <Projects
          setOpen={setOpen}
          onSelect={onSelect}
          setToggleImpressum={setToggleImpressum}
          toggleImpressum={toggleImpressum}
          setToggleDataProtection={setToggleDataProtection}
          dataProtectionToggle={dataProtectionToggle}
        />
      </div>
    </div>
  );
}
export default Landing;
