import './Item.css';
import { useState } from 'react';
function Item({ logoPath, title, itemIndex, className, onSelect }){

    const [selected, setSelected] = useState(false);
    
    const onItemClick = () => {
        onSelect(itemIndex);
        setSelected(!selected);
    }

    return (
        <>
            <div onClick={onItemClick} className={`project-item ${selected ? 'active' : ''}`}>
                <div className="item-img-container"><img src={logoPath} className={`item-img ${className}`} alt="logo"/></div>
                <p className="item-subtitle">{title}</p> 
            </div>
        </>
    )
} export default Item;