import React from "react";
import burger from "../assets/burger.png";
import "./Navigator.css";

function MobileNavigator({ setOpen, isOpen, coloredClass }) {
  return (
    <>
      <img
        alt="mobile-menu"
        src={burger}
        id="mobile-navigator"
        onClick={() => setOpen(!isOpen)}
        className={coloredClass}
      />
    </>
  );
}
export default MobileNavigator;
