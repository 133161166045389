import thesis from "./assets/thesis.png";
//import fissler from "./assets/fissler.jpg";
import sap from "./assets/sap_logo.png";
import iso20022 from "./assets/iso20022.svg";
import java from "./assets/java.png";
import wildfly from "./assets/wildfly.png";

export const projectsData = [
  {
    title: "Java & React Entwicklung",
    fullTitle:
      "Entwicklung einer skalierbaren Cloud Webanwendung mit Spring Boot und Microservices",
    category: "Arbeitnehmertätigkeit",
    className: "item-img-portrait",
    logoPath: java,
    duration: "1 Jahr und 6 Monate",
    branche: "Banking",
    technology:
      "Java, Spring Boot, Spring Cloud, OpenAPI, React, MapStruct, ActiveMQ, Kubernetes, Google Cloud Platform, Webpack etc.",
    description:
      "Seit April 2021 sind Klauseln in den Allgemeinen Geschäftsbedingungen einer Bank unwirksam, wenn diese ohne inhaltliche Einschränkung die Zustimmung des Kunden fingieren (Urt. 27.04.2021, Az. XI ZR 26/20). Um diese Anforderung umzusetzen, wurde eine neue Webanwendung bei einer internationalen Großbank entwickelt. Es ging um die Durchführung von Kampagnen und der zentralen und digitalen Verarbeitung von Millionen von Zustimmungen zu Bankprodukten, Marketingeinwilligungen und anderen Opt-Ins. Das System musste über ein Gateway für das Online-Banking und andere Legacy Umsysteme erreichbar sein. Um den Status und mögliche Events abzubilden, wurde im Kern der Anwendung eine Zustandsmaschine mit Spring Statemachine entwickelt. Mein größter Erfolg war die Entwicklung eines performanten asynchronen CSV-Exports der Zustimmungen zum einfachen Controlling der Kampagnen durch Bankmitarbeiter.",
  },
  /*
  {
    title: "SAP Integration Suite",
    fullTitle: "Einführung SAP Sales Cloud: Datenintegration",
    category: "Selbständige Tätigkeit",
    logoPath: fissler,
    duration : "6 Monate",
    branche: "Konsumgüter",
    technology:
      "SAP Sales Cloud, SAP Business Technology Platform, SAP Cloud Platform Integration, SAP Cloud Connector, Cloud Foundry, Netzwerkarchitektur, ABSL, ABAP",
    description: "Bei der Fissler GmbH habe ich bei der Einführung der SAP Sales Cloud, SAP BTP (Cloud Foundry) und SAP CPI eine zentrale Rolle im IT Bereich eingenommen. Die Hauptaufgabe bestand im Customizing und der Entwicklung der Datenintegration. Die SAP Integration Suite wurde auf einer Cloud Foundry Umgebung gehostet, welche von mir Konfiguriert wurde. Dazu gehörte bspw. die Einrichtung von Zertifikaten auf der SAP BTP und im SAP ECC Backend und die die Berechtigung von Administratoren. Um die sichere eingehende Verbindung zwischen der Cloud und der on-Premises Landschaft herzustellen wurde die Einrichtung des SAP Cloud Connectors von mir durchgeführt. Zusätzlich habe ich bei SAP Basis Aufgaben (ICM Parameter, Technische Nutzer, etc.) und bei der Konfiguration der SAP Sales Cloud unterstützt (Kommunikationssystem, Kummunikationsvereinbarung, UI-Regeln etc.). Kernaufgabe war die Einrichtung der Integrationsprozesse auf der SAP CPI (kundenspezifisches Post,- und Preprocessing und Standardintegration). Mein größter Erfolg war die Erfolgreiche Umsetzung einer kundenspezifischen Anforderung in der SAP Sales Cloud mit einer ABSL-Entwicklung bei welcher ein Webservice im SAP ECC Backend aufgerufen wurde um die externe Nummernfindung bei Debitoren in Abhängigkeit der Kundengruppe umzusetzen."
  }, */
  {
    title: "SAP Sales Cloud",
    logoPath: sap,
    category: "Arbeitnehmertätigkeit",
    branche: "Maschinenbau, Elektrotechnik",
    duration: "2 Jahre",
    technology:
      "SAP Business Technology Platform, SAP Cloud Integration, Datenkovertierung",
    description:
      "Die SAP Sales Cloud ist ein Cloud basiertes CRM-System von SAP. Es wird in der Cloud auf der SAP BTP als SaaS gehostet. Über zwei Jahre hinweg habe ich an der Einführung des Systems in internationalen Tochtergesellschaften eines großen Industrieunternehmens gearbeitet. Mein größter Erfolg war die Umsetzung eines synchronen Webservices in ABAP, welcher die Belegdokumente (Angebot, Lieferschein, ...) aus dem SAP ECC Backend Archiv liest und an die SAP Sales Cloud über einen kundenspezifischen Integrationsprozess auf der SAP CPI sendet.",
  },
  {
    title: "ISO 20022 Datenkonverter",
    logoPath: iso20022,
    className: "item-img-portrait",
    category: "Arbeitnehmertätigkeit",
    branche: "Banking",
    duration: "8 Monate",
    technology: "Testautomatisierung, Jakarta Messaging",
    description:
      "In der ISO 20022 ist das Format für den internationalen Zahlungsverkehr von Banken und Finanzinstituten festgelegt. Um das alte MT Format für Transaktionen auf das neue XML Format zu migrieren, setzen viele Banken auf einen Datenkonverter. Für eine Großbank habe ich an der Implementierung des Datenkonverters im Bereich Testing gearbeitet. Mein größter Erfolg war die Einführung von Testautomatisierung.",
  },
  {
    title: "Java EE Datenkonverter",
    fullTitle: "Migrieren eines Datenkonverters (CSV zu XML) zur java.time API",
    logoPath: wildfly,
    category: "Arbeitnehmertätigkeit",
    branche: "Banking",
    duration: "2 Monate",
    technology: "Java 11, Java EE, Wildfly, JUnit",
    description:
      "In diesem Projekt ging es darum, eine Java EE Anwendung von der joda.time API auf java.time umzustellen. Da alle Buchungsbelege mit einem Zeitstempel versehen sind, waren viele Module des Projekts in Änderungen involviert. Zu den Aufgaben gehörte unter anderem die Anpassung von Testfällen, das Lösen von Git-Merge Konflikten und die Bereitstellung der Anwendung auf einem Wildfly Server.",
  },
  {
    title: "Thesis",
    fullTitle:
      "Thesis: Entwicklung intelligenter Enterprise Webanwendungen mit integrierten künstlichen neuronalen Netzen",
    logoPath: thesis,
    category: "Arbeitnehmertätigkeit",
    branche: "Maschinenbau, Elektrotechnik",
    duration: "1 Jahr",
    technology: "Künstliche Intelligenz, Java, Spring Framework, Python, REST",
    description:
      "Die Anwendung von Künstlicher Intelligenz, insbesondere der Bereich des maschinellen Lernens ermöglicht die Lösung von neuen Problemen. Vor allem die Verarbeitung von Texten stellt eine besondere Herausforderung für Computer dar. In meiner Thesis wird das BERT-Modell verwendet, um das Problem der Textklassifizierung zu lösen. Ich habe das BERT-Modell im Prozess des Fine-Tunings auf problemspezifischen Datensätzen trainiert. Nach dem Training wurden Vorhersagen auf neuen Testdatensätzen analysiert und die Performanz bewertet. Die Trefferquote der KI auf Testdaten erreicht 85%. Im Zuge des Projekts ist eine Webanwendung mit integrierter künstlicher Intelligenz auf Basis von unabhängigen Webservices entstanden. Dadurch können Anwender unterstützt und die Wirtschaftlichkeit von Unternehmen erhöht werden.",
  },
];
