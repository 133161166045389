import { BrowserRouter, Routes, Route } from "react-router-dom";
import Contact from "./container/Contact";
import Landing from "./container/Landing";
import Impressum from "./components/Impressum";
import DataProtection from "./components/DataProtection";
import { useState } from "react";
import DetailsModal from "./components/DetailsModal";
import { projectsData } from "./projects-data";
import MobileNavigator from './components/MobileNavigator';

function App() {
  const [toggleImpressum, setToggleImpressum] = useState();
  const [toggleProject, setToggleProject] = useState();
  const [dataProtectionToggle, setToggleDataProtection] = useState();
  const [selectedProject, setSelectedProject] = useState();
  const [isOpen, setOpen] = useState(false);

  const onSelect = (index) => {
    setSelectedProject(projectsData[index]);
    setToggleProject(!toggleProject);
  };

  const Sidebar = () => {

    return (
      <div className={isOpen ? "sidebar-on" : "sidebar-off"}>
        <div className="spacer-small">&nbsp;</div>
        <div className="mobile-navigation-header">
          <MobileNavigator setOpen={setOpen} isOpen={isOpen} coloredClass="colored-class" />
        </div>
        <div className="spacer">&nbsp;</div>
        <ul>
          <li>
            <a href="/#home">
              Home
          </a>
          </li>
          <li>
            <a href="/#projects">
              Projekte
          </a>
          </li>
          <li>
            <a href="/contact">
              Kontakt
          </a>
          </li>
        </ul>
      </div>
    )
  }
//e.currentTarget !== document.getElementsByClassName('sidebar-on')[0] && isOpen == true ? setOpen(false) : "" 
  return (
    <BrowserRouter>
      <div onClick={(e) => isOpen === true && !document.getElementsByClassName('sidebar-on')[0].contains(e.target) ? setOpen(false) : ""}>
        <Sidebar />
        {toggleProject ? (
          <DetailsModal
            selectedProject={selectedProject}
            toggleProject={toggleProject}
            setToggleProject={setToggleProject}
          />
        ) : (
          ""
        )}
        {toggleImpressum ? (
          <Impressum setToggleImpressum={setToggleImpressum} toggleImpressum={toggleImpressum} />
        ) : (
          ""
        )}
        {dataProtectionToggle ? (
          <DataProtection setToggleDataProtection={setToggleDataProtection} dataProtectionToggle={dataProtectionToggle} />
        ) : (
          ""
        )}
        <Routes>
          <Route
            path="/"
            element={
              <Landing
                setOpen={setOpen}
                onSelect={onSelect}
                setToggleImpressum={setToggleImpressum}
                toggleImpressum={toggleImpressum}
                setToggleDataProtection={setToggleDataProtection}
                dataProtectionToggle={dataProtectionToggle}
              />
            }
          />
          <Route
            path="/contact"
            element={
              <Contact
                setToggleImpressum={setToggleImpressum}
                toggleImpressum={toggleImpressum}
                setToggleDataProtection={setToggleDataProtection}
                dataProtectionToggle={dataProtectionToggle}
                setOpen={setOpen}
                isOpen={isOpen}
              />
            }
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
}
export default App;
