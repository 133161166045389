import "./Navigator.css";
import React from "react";
import MobileNavigator from '../components/MobileNavigator';

function Navigator({currentNavigation, setOpen }) {
  return (
    <>
    <MobileNavigator setOpen={setOpen}/>
      <ul id="navigator-ul">
        <li>
          <a className={currentNavigation === 'home' ? 'current-navigation': '' } href="/#home">
            Home
          </a>
        </li>
        <li>
          <a className={currentNavigation === 'projects' ? 'current-navigation': '' } href="/#projects">
            {" "}
            Projekte
          </a>
        </li>
        <li>
          <a className={currentNavigation === 'contact' ? 'current-navigation': '' } href="/contact">
            Kontakt
          </a>
        </li>
      </ul>
    </>
  );
}

export default Navigator;


