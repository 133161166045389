import "./Hero.css";
import me from "../assets/me.png";
import elipse from "../assets/elipse.png";
import Navigator from "../components/Navigator";

function Hero({ setOpen }) {
  return (
    <div id="home">
      <header>
        <p className="logo">
          <p>Daniel Mantler</p>
          <span class="dot"></span>
        </p>
        <Navigator
          currentNavigation="home"
          setOpen={setOpen}
          className="navigator"
        />
      </header>
      <div class="hero">
        <p class="hero-big">Deliver Solutions</p>
        <div>
          <p class="hero-small">not problems</p>
        </div>
        <div class="hero-buttons">
          <a href="/#projects" id="project-button">
            Projekte
          </a>
          <a href="/contact" className="contact-button">
            Kontakt
          </a>
        </div>
      </div>
      <div id="me-container">
        <div id="self">
          <div id="center-image">
            <img id="elipse" src={elipse} alt="elipse" />
            <img id="me" src={me} alt="me" />
          </div>
        </div>
      </div>
      <div className="about-me">
        <div className="transparent-bg" />
        <h3 className="about-me-header">Über mich</h3>
        <div className="about-me-content">
          <span className="about-me-text">
            Ich bin leidenschaftlicher <b>IT-Berater</b> mit dem Schwerpunkt{" "}
            <b>Softwareentwicklung</b> und <b>IT-Architektur</b> und Experte für{" "}
            <b>Java</b> Entwicklung und <b>Datenintegration</b>. Vor allem
            interessiere ich mich für die Branche <b>Banking</b> and{" "}
            <b>Finance</b>, in welcher ich mehrere Jahre Erfahrung in
            IT-Projekten gesammelt habe. Durch den Einsatz moderner{" "}
            <b>Technologien</b>, agiler <b>Methoden</b>, hohem Verständnis für{" "}
            <b>Geschäftsprozesse</b> und einer sehr großen <b>Motivation</b>{" "}
            helfe ich meinen Kunden zum <b>Erfolg</b>.{" "}
          </span>
          <div className="about-me-seperator"> </div>
          <div className="about-me-bullets">
            <ul>
              <li id="map-pointer">Bonn</li>
              <li id="building">Freiberufler</li>
              <li id="suitcase">
                IT-Berater (Softwareentwicklung, IT-Architektur){" "}
              </li>
              <li id="bachelor">B.A. BWL - Wirtschaftsinformatik [Ø 1,0]</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Hero;
