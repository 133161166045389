import "./Impressum.css"
import close from "../assets/close.png";

const Impressum = ({ setToggleImpressum, toggleImpressum }) => {
  return (
    <div
      className="modal"
      onClick={(e) =>
        e.target === e.currentTarget ? setToggleImpressum(!toggleImpressum) : ""
      }
    >
      <div className="modal-content-small">
      <span
        className="close"
        onClick={() => setToggleImpressum(!toggleImpressum)}
      >
        <img src={close} alt="close" />
      </span>
        <div className="center-container-small">
          <div className="form-group">
            <div className="center-input-small">
              <span className="small-input"> <span style={{ color: "black" }}><h3>Impressum</h3></span></span>
            </div>
          </div>
          <div className="form-group">
            <div className="left-container-small">Name:</div>
            <div className="center-input-small">
              <span className="small-input">Daniel Mantler</span>
            </div>
          </div>
          <div className="form-group">
            <div className="left-container-small">Anschrift:</div>
            <div className="center-input-small">
              <span className="small-input">Burgweg 38<br />53123 Bonn</span>
            </div>
          </div>
          <div className="form-group">
            <div className="left-container-small">Telefon:</div>
            <div className="center-input-small">
              <span className="small-input">+49 1712093967</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Impressum;
