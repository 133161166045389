import "./Projects.css";
import React from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import Navigator from "../components/Navigator";
import Item from "../components/Item";
import Arrow from "../components/Arrow";
import { projectsData } from "../projects-data";
import arrowLeft from "../assets/arrow-left.png";
import arrowRight from "../assets/arrow-right.png";
import Footer from "../components/Footer";

const Projects = ({
  setOpen,
  onSelect,
  setToggleImpressum,
  toggleImpressum,
  setToggleDataProtection,
  dataProtectionToggle,
}) => {
  return (
    <div id="projects">
      <div className="projects-background" />
      <header>
        <p id="projects-title">PROJEKTE</p>
        <Navigator
          setOpen={setOpen}
          isOpen={false}
          currentNavigation="projects"
        />
      </header>
      <div id="projects-container">
        <ScrollMenu
          LeftArrow={LeftArrow}
          RightArrow={RightArrow}
          wrapperClassName="project-scroll-menu-wrapper"
          scrollContainerClassName="project-scroll-container"
          itemClassName="project-scroll-menu-item-wrapper"
        >
          {projectsData.map((item, index) => {
            const { title, logoPath, className } = item;
            return (
              <Item
                logoPath={logoPath}
                title={title}
                itemIndex={index}
                className={className}
                onSelect={onSelect}
              />
            );
          })}
        </ScrollMenu>
      </div>
      <Footer
        setToggleImpressum={setToggleImpressum}
        toggleImpressum={toggleImpressum}
        setToggleDataProtection={setToggleDataProtection}
        dataProtectionToggle={dataProtectionToggle}
      />
    </div>
  );
};
export default Projects;

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } =
    React.useContext(VisibilityContext);

  return (
    <Arrow
      disabled={isFirstItemVisible}
      onClick={() => scrollPrev()}
      path={arrowLeft}
      className={"arrow-prev"}
    >
      Left
    </Arrow>
  );
}

function RightArrow() {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

  return (
    <Arrow
      disabled={isLastItemVisible}
      onClick={() => scrollNext()}
      path={arrowRight}
      className={"arrow-next"}
    >
      Right
    </Arrow>
  );
}
