function Arrow({ disabled, onClick, path, className }) {
  return (
    <img
      disabled={disabled}
      onClick={onClick}
      className={className}
      src={path}
      alt="arrow"
    />
  );
} export default Arrow;