import React from "react";
import "./DetailsModal.css";
import close from "../assets/close.png";

function DetailsModal({ selectedProject, toggleProject, setToggleProject }) {
  return (
    <div
      className="modal"
      onClick={(e) =>
        e.currentTarget === e.target ? setToggleProject(!toggleProject) : ""
      }
    >
      <div className="modal-content">
        <span
          className="close"
          onClick={() => setToggleProject(!toggleProject)}
        >
          {" "}
          <img src={close} alt="close" />{" "}
        </span>
        <div className="center-container">
          <img
            src={selectedProject.logoPath}
            className={selectedProject.className}
            alt="selected"
          ></img>
        </div>
        <div className="center-container-large">
          <div className="form-group">
            <div className="left-container">Titel:</div>
            <div className="center-input">
              <span className="large-input">
                {selectedProject.fullTitle == null
                  ? selectedProject.title
                  : selectedProject.fullTitle}
              </span>
            </div>
          </div>
          <div className="form-group">
            <div className="left-container">Kategorie:</div>
            <div className="center-input">
              <span className="large-input">{selectedProject.category}</span>
            </div>
          </div>
          <div className="form-group">
            <div className="left-container">Branche:</div>
            <div className="center-input">
              <span className="large-input">{selectedProject.branche}</span>
            </div>
          </div>
          <div className="form-group">
            <div className="left-container">Dauer:</div>
            <div className="center-input">
              <span className="large-input">{selectedProject.duration}</span>
            </div>
          </div>
          <div className="form-group">
            <div className="left-container">Technologie:</div>
            <div className="center-input">
              <span className="large-input">{selectedProject.technology}</span>
            </div>
          </div>
          <div className="form-group">
            <div className="left-container">Projektbeschreibung:</div>
            <div className="center-input">
              <span className="large-input">{selectedProject.description}</span>
            </div>
          </div>
          <div className="form-group last-group">
            <div className="center-input">
              <a href="/contact" className="contact-button-green">
                Kontakt
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default DetailsModal;
