import React, { useState } from "react";
import snow from "../assets/snow.mp4";
import snowWebm from "../assets/snow.webm";
import "./Contact.css";
import Navigator from "../components/Navigator";
import Footer from "../components/Footer";

const errorMessage =
  "Der Server ist momentan nicht erreichbar. Bitte rufen sie mich unter +49 1712093967 an.";

const Contact = ({
  setToggleImpressum,
  toggleImpressum,
  setToggleDataProtection,
  dataProtectionToggle,
  setOpen,
  isOpen,
}) => {
  const sendMail = (event) => {
    event.preventDefault();

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formValue),
    };

    fetch(process.env.REACT_APP_SERVER_MAIL_API, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          alert("Vielen Dank für Ihre Anfrage.");
        } else {
          alert(errorMessage);
        }
      })
      .catch((e) => {
        alert(errorMessage);
      });
  };

  const [formValue, setFormValue] = useState({
    name: "",
    lastname: "",
    email: "",
    content: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValue((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const { forename, surename, email, content } = formValue;

  return (
    <>
      <div className="contact">
        <div
          style={{ display: "contents" }}
          dangerouslySetInnerHTML={{
            __html: `<video autoplay loop muted playsinline>
      <source src=${snow} type="video/mp4" />
      <source src=${snowWebm} type="video/webm"/>
      Your browser does not support the video tag.
</video>`,
          }}
        />
        <header>
          <p className="logo">
            <p>Daniel Mantler</p>
            <span class="dot"></span>
          </p>
          <Navigator
            setOpen={setOpen}
            isOpen={isOpen}
            currentNavigation="contact"
          />
        </header>
        <div className="contact-content">
          <span className="slogan">Let's talk about you</span>
          <div className="contact-pane">
            <div className="contact-form">
              <form onSubmit={sendMail}>
                <input
                  value={forename}
                  onChange={handleChange}
                  name="name"
                  id="name"
                  type="text"
                  placeholder="Vorname"
                ></input>
                <input
                  value={surename}
                  onChange={handleChange}
                  name="lastname"
                  id="lastname"
                  type="text"
                  placeholder="Nachname"
                ></input>
                <input
                  value={email}
                  onChange={handleChange}
                  name="email"
                  id="email"
                  type="text"
                  placeholder="E-Mail"
                ></input>
                <textarea
                  value={content}
                  onChange={handleChange}
                  name="content"
                  id="content"
                  placeholder="Anfrage"
                  rows="5"
                ></textarea>
                <button className="contact-button-green">Senden</button>
              </form>
            </div>
            <div className="contact-info">
              <span>KONTAKTDATEN</span>
              <div className="contact-info-content">
                <div id="contact-and-bullets">
                  <div className="contact-bullets">
                    <ul>
                      <li id="phone">+49 171 2093967</li>
                      <li id="mail">it-consulting@daniel-mantler.de</li>
                      <li id="pointer-white">Bonn</li>
                    </ul>
                  </div>
                </div>
              </div>
              <iframe
                title="map"
                className="contact-info-map"
                src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d40399.945895792!2d7.100184656005865!3d50.73855159204462!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sde!2sde!4v1668440164415!5m2!1sde!2sde"
                width="400"
                height="450"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
        <Footer
          setToggleImpressum={setToggleImpressum}
          toggleImpressum={toggleImpressum}
          setToggleDataProtection={setToggleDataProtection}
          dataProtectionToggle={dataProtectionToggle}
        />
      </div>
    </>
  );
};

export default Contact;
