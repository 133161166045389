import "./Footer.css";
import React from "react";

function Footer({ setToggleImpressum, impressumToggle, setToggleDataProtection, dataProtectionToggle }) {
  
  return (
    <>
      <span className="link">
        <p onClick={() => setToggleDataProtection(!dataProtectionToggle)}>Datenschutz</p>
        <p onClick={() => setToggleImpressum(!impressumToggle)}>Impressum</p>
      </span>
    </>
  );
}
export default Footer;
